import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { BUTTON_SAVE, CLOSE } from '@engined/client/locales.js';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, DialogActions } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { useFormState } from 'react-hook-form';

interface OwnProps {
  className?: string;
  sx?: SxProps;
  isSubmitting?: boolean;
  submitLabel?: React.ReactNode;
  submitOnClick?: ButtonProps['onClick'];
  submitAutoFocus?: ButtonProps['autoFocus'];
  submitColor?: ButtonProps['color'];
  submitDisabled?: ButtonProps['disabled'];
  closeLabel?: React.ReactNode;
  closeOnClick?: ButtonProps['onClick'];
  children?: React.ReactNode;
}

type Props = OwnProps;

const DialogSubmitButtons: React.FunctionComponent<Props> = ({
  className,
  sx,
  isSubmitting,
  submitLabel,
  submitOnClick,
  submitAutoFocus,
  submitColor = 'primary',
  submitDisabled,
  closeLabel,
  closeOnClick,
  children,
}) => {
  const { t } = useLocale();

  return (
    <DialogActions className={className} sx={sx}>
      {children}
      <Box>
        {closeOnClick && (
          <Button type="button" onClick={closeOnClick} disabled={isSubmitting} size="medium">
            {closeLabel ?? t(CLOSE)}
          </Button>
        )}{' '}
        <LoadingButton
          type="submit"
          color={submitColor}
          loading={isSubmitting}
          onClick={submitOnClick}
          size="medium"
          autoFocus={submitAutoFocus}
          disabled={submitDisabled}
        >
          {submitLabel ?? t(BUTTON_SAVE)}
        </LoadingButton>
      </Box>
    </DialogActions>
  );
};

DialogSubmitButtons.displayName = 'DialogSubmitButtons';

export default DialogSubmitButtons;

// TODO: Swap to default when converted all components
export const ConnectedDialogSubmitButtons: React.FC<Omit<Props, 'isSubmitting'>> = (props) => {
  const { isSubmitting } = useFormState();
  return <DialogSubmitButtons isSubmitting={isSubmitting} {...props} />;
};
