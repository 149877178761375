import ConfirmDialog from '@engined/client/components/dialogs/ConfirmDialog.js';
import React from 'react';
import { useBlocker } from 'react-router-dom';

export interface PromptProps {
  message: string;
  when?: boolean;
}

export function Prompt({ message, when }: PromptProps) {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if ((nextLocation.state as { ignoreFormPrompt?: boolean } | null)?.ignoreFormPrompt === true) {
      return false;
    }

    return when;
  });

  return (
    <ConfirmDialog
      open={blocker.state === 'blocked' || blocker.state === 'proceeding'}
      onOK={() => {
        blocker.proceed();
      }}
      onCancel={() => {
        blocker.reset();
      }}
      loading={blocker.state === 'proceeding'}
      question={message}
    />
  );
}
