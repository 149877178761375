import { Option as AutocompleteOption } from '@engined/client/components/forms/fields/AutocompleteField.js';
import { Option as SelectOption } from '@engined/client/components/forms/fields/SelectField.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import { useCallback } from 'react';
import { FieldErrors, FieldValues, RegisterOptions, Resolver, ResolverOptions } from 'react-hook-form';

type IsAny<T> = 0 extends 1 & T ? true : false;
type NonUndefined<T> = T extends undefined ? never : T;

type TupleSplit<T, N extends number, O extends readonly any[] = readonly []> = O['length'] extends N
  ? [O, T]
  : T extends readonly [infer F, ...infer R]
  ? TupleSplit<readonly [...R], N, readonly [...O, F]>
  : [O, T];
type SkipFirst<T extends readonly any[], N extends number> = TupleSplit<T, N>[1];

declare const $NestedValue: unique symbol;
type NestedValue<TValue extends object = object> = {
  [$NestedValue]: never;
} & TValue;

type BrowserNativeObject = Date | FileList | File;
type DeepPartial<T> = T extends BrowserNativeObject | NestedValue
  ? T
  : {
      [K in keyof T]?: DeepPartial<T[K]>;
    };
type DeepMap<T, TValue> = IsAny<T> extends true
  ? any
  : T extends BrowserNativeObject | NestedValue | AutocompleteOption | SelectOption
  ? TValue
  : T extends object
  ? {
      [K in keyof T]: DeepMap<NonUndefined<T[K]>, TValue>;
    }
  : TValue;

export type FormErrorsOverride<TFormValues, TKeys extends keyof TFormValues> = {
  [K in keyof Omit<TFormValues, TKeys>]: TFormValues[K];
} & {
  [K in TKeys]?: string;
};

export type FormErrors<TFormValues> = DeepPartial<DeepMap<TFormValues, string>>;

export function useFormResolver<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  validate: (
    values: TFieldValues,
    t: LocaleContextValue['t'],
    context: TContext | undefined,
    options?: ResolverOptions<TFieldValues>,
    ...rest: unknown[]
  ) => FormErrors<TFieldValues> | Promise<FormErrors<TFieldValues>>,
  ...rest: SkipFirst<Parameters<typeof validate>, 4>
): Resolver<TFieldValues, TContext> {
  const { t } = useLocale();
  return useCallback(
    async (values: TFieldValues, context, options) => {
      let errors = await validate(values, t, context, options, ...rest);

      // Add field errors
      errors = await traverse(values, errors, options.fields);

      return {
        values,
        errors: convertErrorsToFieldErrors(errors),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, validate, ...rest],
  );
}

function convertErrorsToFieldErrors<TFormValues>(errors: FormErrors<TFormValues>): FieldErrors<TFormValues> {
  if (!errors) {
    return {};
  }

  return Object.keys(errors).reduce<FieldErrors<TFormValues>>((acc, cur) => {
    const current = errors[cur];
    if (Array.isArray(current)) {
      const mapped = current.map(convertError);
      if (mapped?.some((v) => v && Object.keys(v).length > 0)) {
        acc[cur] = mapped;
      }
    } else {
      const mapped = convertError(current);
      if (mapped && Object.keys(mapped).length > 0) {
        acc[cur] = mapped;
      }
    }
    return acc;
  }, {});
}

function convertError<TFormValues>(error: string | FormErrors<TFormValues>) {
  if (typeof error === 'string') {
    return { type: 'validate', message: error };
  } else {
    return convertErrorsToFieldErrors(error);
  }
}

async function traverse<TFormValues>(
  values: TFormValues,
  errors: FormErrors<TFormValues>,
  fields: Record<string, RegisterOptions>,
) {
  for (const [key, field] of Object.entries(fields)) {
    if (Array.isArray(field)) {
      errors[key] = await Promise.all(field.map((v, i) => traverse(values[key][i], errors[key]?.[i] ?? {}, v)));
    } else if (field?.validate) {
      if (typeof field.validate === 'function') {
        const error = await field.validate(values[key], values);
        if (error) {
          errors[key] = Array.isArray(error) ? error[0] : typeof error === 'boolean' ? '' : error;
        }
      }
    }
  }

  return errors;
}
