import { Prompt } from '@engined/client/components/forms/Prompt.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import { useFormResolver } from '@engined/client/hooks/useFormResolver.js';
import { FORM_IS_DIRTY_PROMPT } from '@engined/client/locales.js';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ForwardedRef, ReactElement, RefAttributes, useEffect } from 'react';
import {
  DeepPartial,
  EventType,
  FieldPath,
  FieldValues,
  FormProvider,
  ResolverOptions,
  useForm,
  UseFormReset,
  UseFormReturn,
  DefaultValues,
} from 'react-hook-form';

export type OnSubmit<TFormValues extends FieldValues, TContext = any> = (
  values: TFormValues,
  form: UseFormReturn<TFormValues, TContext>,
) => Promise<void> | void;

export type OnChange<TFormValues extends FieldValues, TContext = any> = (
  value: DeepPartial<TFormValues>,
  info: {
    name?: FieldPath<TFormValues>;
    type?: EventType;
  },
  form: UseFormReturn<TFormValues, TContext>,
) => void;

interface OwnProps<TFormValues extends FieldValues, TContext = any> {
  defaultValues?: DefaultValues<TFormValues>;
  values?: TFormValues;
  validate?(
    values: TFormValues,
    t: LocaleContextValue['t'],
    context: TContext | undefined,
    options?: ResolverOptions<TFormValues>,
    ...rest: unknown[]
  );
  onSubmit: OnSubmit<TFormValues, TContext>;
  onChange?: OnChange<TFormValues, TContext>;
  context?: TContext;
  sx?: SxProps;
  enableDirtyCheck?: boolean;
  children: ((form: UseFormReturn<TFormValues, TContext>) => React.ReactNode) | React.ReactNode;
  stopSubmitPropagation?: boolean;
  resetOptions?: Parameters<UseFormReset<TFormValues>>[1];
  disableSubmitOnEnter?: boolean;
}

export type Props<TFormValues extends FieldValues, TContext = any> = OwnProps<TFormValues, TContext> &
  Omit<React.FormHTMLAttributes<HTMLFormElement>, 'children' | 'onSubmit' | 'onChange'>;

function Form<TFormValues extends FieldValues, TContext = any>(
  {
    defaultValues,
    values,
    validate = () => ({}),
    onSubmit,
    context,
    action = '#',
    method = 'post',
    noValidate = true,
    children,
    enableDirtyCheck = false,
    stopSubmitPropagation = false,
    disableSubmitOnEnter = false,
    onChange,
    resetOptions,
    ...rest
  }: Props<TFormValues, TContext>,
  ref: ForwardedRef<HTMLFormElement>,
) {
  const form = useForm<TFormValues, TContext>({
    defaultValues,
    values,
    resolver: useFormResolver(validate),
    context,
    resetOptions,
  });

  const { t } = useLocale();

  useEffect(() => {
    if (onChange) {
      const subscription = form.watch((data, change) => onChange(data, change, form));
      return () => subscription.unsubscribe();
    }
  }, [onChange, form]);

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        ref={ref}
        action={action}
        method={method}
        noValidate={noValidate}
        onKeyDown={
          disableSubmitOnEnter
            ? (event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }
            : undefined
        }
        onSubmit={(event) => {
          if (stopSubmitPropagation) {
            event.stopPropagation();
          }
          form.handleSubmit((values) => {
            onSubmit(values, form);
          })(event);
        }}
        {...rest}
      >
        {enableDirtyCheck && <Prompt when={form.formState.isDirty} message={t(FORM_IS_DIRTY_PROMPT)} />}
        {typeof children === 'function' ? children(form) : children}
      </Box>
    </FormProvider>
  );
}

Form.displayName = 'Form';

export default React.forwardRef(Form) as <TFormValues extends FieldValues, TContext = any>(
  props: Props<TFormValues, TContext> & RefAttributes<HTMLFormElement>,
) => ReactElement | null;
