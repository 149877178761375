import DialogSubmitButtons from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { CONFIRM_DIALOG_CANCEL, CONFIRM_DIALOG_OK, CONFIRM_DIALOG_TITLE } from '@engined/client/locales.js';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Breakpoint } from '@mui/system';
import React, { useState } from 'react';

interface OwnProps {
  className?: string;
  open: boolean;
  loading?: boolean;
  title?: React.ReactNode;
  question: React.ReactNode;
  okButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  maxWidth?: Breakpoint | false;
  onOK?(event: React.MouseEvent<HTMLButtonElement>): Promise<void> | void;
  onCancel?(event: React.MouseEvent<HTMLButtonElement>): Promise<void> | void;
}

type Props = OwnProps;

const ConfirmDialog: React.FunctionComponent<Props> = ({
  open,
  onCancel,
  onOK,
  className,
  title,
  question,
  okButton,
  cancelButton,
  maxWidth = 'sm',
  loading = false,
}) => {
  const { t } = useLocale();
  const [processing, setProcessing] = useState<boolean>(false);

  const onCancelCallback = useEventCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
    const promise = onCancel?.(event);
    if (promise && 'then' in promise) {
      setProcessing(true);
      promise.finally(() => {
        setProcessing(false);
      });
    }
  });

  const onOKCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const promise = onOK?.(event);
    if (promise && 'then' in promise) {
      setProcessing(true);
      promise.finally(() => {
        setProcessing(false);
      });
    }
  });

  return (
    <Dialog className={className} open={open} onClose={onCancelCallback} fullWidth maxWidth={maxWidth}>
      <DialogTitle>{title ?? t(CONFIRM_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogSubmitButtons
        isSubmitting={loading || processing}
        closeOnClick={onCancelCallback}
        closeLabel={cancelButton ?? t(CONFIRM_DIALOG_CANCEL)}
        submitLabel={okButton ?? t(CONFIRM_DIALOG_OK)}
        submitOnClick={onOKCallback}
      />
    </Dialog>
  );
};

ConfirmDialog.displayName = 'ConfirmDialog';

export default React.memo(ConfirmDialog);
